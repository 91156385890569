@import "../../variables.modules";

.dark {
    background-color: $terminal !important;
    transition: all 400ms;
}

.light {
    background-color: $white !important;
    transition: all 400ms;
}

i {
    padding-left: 0.5rem;
  
    &.red {
      color: $red;
    }
  
    &.amber {
      color: $yellow;
    }
  
    &.green {
      color: $green;
    }
  }